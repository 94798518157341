import { IObservableObject, action, computed, observable } from 'mobx'
import { arrayMove } from 'react-sortable-hoc'

import {
  IWorkflowsFilter,
  LocationType,
  WorkflowsFilterType,
} from '~/client/graph'
import { workflowsFilterTypes } from '~/client/src/desktop/enums/WorkflowsFilterType'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import { ISavedViewSettings } from '~/client/src/shared/enums/ProjectSpecificUserProps'
import { SavedViewEditableFields } from '~/client/src/shared/enums/SavedViewEditableFields'
import Permit from '~/client/src/shared/models/Permit'
import User from '~/client/src/shared/models/User'
import { SET_FILTER_SELECTION } from '~/client/src/shared/stores/EventStore/eventConstants'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomWorkflowsListFiltersStore from '~/client/src/shared/stores/domain/CustomWorkflowsListFilter.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import CustomWorkflowsFilter, {
  FilterValue,
} from '~/client/src/shared/types/CustomWorkflowFilter'
import LocalStorageHelper from '~/client/src/shared/utils/LocalStorageHelper'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  LOCATION_SEPARATOR,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import LogisticsStore from '../../../views/Logistics/Logistics.store'
import LogisticsGroupingOptionsStore from '../../../views/Logistics/components/LogisticsHeaderBar/components/LogisticsGroupingOptions/LogisticsGroupingOptions.store'
import DesktopSavedFiltersStore from '../SavedFilters/DesktopSavedFilters.store'

// localization: no display text to translate

const isFilterTypeRelevantForViewMode = (
  type: WorkflowsFilterType,
): boolean => {
  return workflowsFilterTypes.includes(type)
}

export default class DesktopCustomWorkflowsFiltersStore extends DesktopSavedFiltersStore {
  @observable public customFilterInstance: CustomWorkflowsFilter = null
  @observable public deletableCustomFilterId: string = null

  public constructor(
    private readonly logisticsFilterStore: LogisticsFilterStore,
    private readonly logisticsStore: LogisticsStore,
    private readonly customWorkflowsListFiltersStore: CustomWorkflowsListFiltersStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    protected readonly eventsStore: DesktopEventStore,
    protected readonly logisticsGroupingOptionsStore: LogisticsGroupingOptionsStore,
    private readonly userProjectsStore: UserProjectsStore,
    protected readonly getItemNameByLocationTypeAndId: (
      type: LocationType,
      id: string,
    ) => string,
    protected readonly filters: IFilters & IObservableObject,
    protected readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  @computed
  private get enabledFilterTypes(): string[] {
    const { filterInfoMap } = this.eventsStore.appState.formsFiltersSettings
    return Object.keys(filterInfoMap)
  }

  public get customFilters(): CustomWorkflowsFilter[] {
    return this.customWorkflowsListFiltersStore.list
  }

  public get selectedCustomFilter(): CustomWorkflowsFilter {
    const filterId = this.selectedCustomFilterId || this.appliedCustomFilterId
    return this.customWorkflowsListFiltersStore.byId.get(filterId)
  }

  public get editableSavedView(): CustomWorkflowsFilter {
    return this.customWorkflowsListFiltersStore.byId.get(
      this.editableSavedViewId,
    )
  }

  public get isSavedViewEdited(): boolean {
    if (!this.isEditingSavedView || !this.editableSavedView) {
      return false
    }

    switch (this.editableSavedViewField) {
      case SavedViewEditableFields.IsPublic:
        return (
          this.editableSavedView.isPublic !== this.editableSavedViewIsPublic
        )

      case SavedViewEditableFields.Name:
        return this.editableSavedView.name !== this.editableSavedViewName

      case SavedViewEditableFields.None:
        return false
    }
  }

  public getSavedViewById = (id: string): CustomWorkflowsFilter => {
    return this.customWorkflowsListFiltersStore.byId.get(id)
  }

  public getSelectedFiltersByFilterTypeInCustomFilter = (
    customFilter: CustomWorkflowsFilter,
  ): IWorkflowsFilter[] => {
    return (
      customFilter?.filtersByFilterType?.filter(filter =>
        this.shouldIncludeInSelectedFiltersMap(filter),
      ) || []
    )
  }

  public getSelectedFilterCountByInCustomFilter = (
    customFilter: CustomWorkflowsFilter,
  ): number => {
    const selectedFiltersByFilterType =
      this.getSelectedFiltersByFilterTypeInCustomFilter(customFilter)

    return selectedFiltersByFilterType.reduce(
      (sum, filter) => sum + (filter.values?.length ?? 0),
      0,
    )
  }

  public getSelectedFilterCountByCustomFilterId = (id: string): number => {
    const customFilter: CustomWorkflowsFilter =
      this.customWorkflowsListFiltersStore.byId.get(id)

    return this.getSelectedFilterCountByInCustomFilter(customFilter)
  }

  public getWorkflowsCountByCustomFilter = (
    filter?: CustomWorkflowsFilter,
  ): number => {
    const selectedFilter = filter || this.customFilterInstance
    return (
      this.workflowsInSelectedFilters(selectedFilter.filtersByFilterType)
        ?.length || 0
    )
  }

  public getUserForCustomFilter = (filter?: CustomWorkflowsFilter): User => {
    const selectedFilter = filter || this.customFilterInstance
    return (
      this.projectMembersStore.getById(selectedFilter.userId) ||
      ({ isHidden: true } as User)
    )
  }

  public getFiltersMapByCustomFilter = (
    customFilter: CustomWorkflowsFilter,
  ): Map<WorkflowsFilterType, FilterValue[]> => {
    const filtersMap: Map<WorkflowsFilterType, any> = new Map<
      WorkflowsFilterType,
      any
    >()

    const { filtersByFilterType: workflowsFiltersByFilterType } = customFilter

    workflowsFiltersByFilterType
      .filter(filter => this.shouldIncludeInSelectedFiltersMap(filter))
      .map(filter => {
        filtersMap[filter.type] =
          this.getSelectedFilters(filter.type, filter.values) || []
      })

    return filtersMap
  }

  public getFiltersMapByCustomFilterId = (
    id: string,
  ): Map<WorkflowsFilterType, FilterValue[]> => {
    const customFilter = this.customWorkflowsListFiltersStore.byId.get(id)

    if (!customFilter) {
      return null
    }

    return this.getFiltersMapByCustomFilter(customFilter)
  }

  private getGroupByCaptionByCustomFilter = (
    customFilter: CustomWorkflowsFilter,
  ): string => {
    return this.logisticsGroupingOptionsStore.getGroupByCaptionByOptionId(
      customFilter.groupBy,
    )
  }

  public getGroupByCaptionByCustomFilterId = (id: string): string => {
    const customFilter = this.customWorkflowsListFiltersStore.byId.get(id)

    if (!customFilter) {
      return null
    }

    return this.getGroupByCaptionByCustomFilter(customFilter)
  }

  @computed
  public get selectedFiltersCount(): number {
    if (!this.customFilterInstance) {
      return 0
    }

    return this.getSelectedFilterCountByInCustomFilter(
      this.customFilterInstance,
    )
  }

  @computed
  public get filtersCount(): number {
    if (!this.customFilterInstance) {
      return 0
    }

    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.customFilterInstance
    return workflowsFiltersByFilterType.reduce((sum, filters) => {
      if (!filters.values) {
        return sum
      }
      return sum + filters.values.length
    }, 0)
  }

  @computed
  public get filtersDescriptions(): string[] {
    if (!this.customFilterInstance) {
      return []
    }
    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.customFilterInstance

    const filtersDescriptions = []
    workflowsFiltersByFilterType
      .filter(filter => {
        return filter.values && filter.values.length
      })
      .map(filter => {
        filtersDescriptions.push(
          (
            this.getSelectedFilterValues(filter.type, filter.values, true) || []
          ).join(', '),
        )
      })
    return filtersDescriptions
  }

  @computed
  public get filtersMap(): Map<WorkflowsFilterType, FilterValue[]> {
    if (!this.customFilterInstance) {
      return null
    }

    return this.getFiltersMapByCustomFilter(this.customFilterInstance)
  }

  @computed
  public get groupByCaption(): string {
    if (!this.customFilterInstance) {
      return null
    }

    return this.getGroupByCaptionByCustomFilter(this.customFilterInstance)
  }

  @computed
  private get userActiveProjectSettings() {
    return this.eventsStore.appState.userActiveProjectSettings
  }

  @computed
  public get savedViewsSettings(): ISavedViewSettings[] {
    return this.userActiveProjectSettings.savedViewsSettings?.length
      ? this.userActiveProjectSettings.savedViewsSettings.concat(
          this.savedViewsWithoutSettings,
        )
      : this.generateSavedViewsSettings()
  }

  @computed
  public get savedViewsWithoutSettings(): ISavedViewSettings[] {
    return this.customFilters
      .filter(
        customFilter =>
          !this.userActiveProjectSettings.savedViewsSettings.some(
            settings => customFilter.id === settings.workflowCustomFilterId,
          ),
      )
      .map(
        savedView =>
          ({
            workflowCustomFilterId: savedView.id,
            isHidden: false,
          } as ISavedViewSettings),
      )
  }

  @action.bound
  public deleteCustomFilter(): void {
    if (!this.selectedCustomFilter) {
      return
    }

    this.deleteCustomFilterById(this.selectedCustomFilter.id)

    this.closeSavedFilters()
  }

  public get deletableCustomFilter(): CustomWorkflowsFilter {
    return this.getSavedViewById(this.deletableCustomFilterId)
  }

  @action.bound
  public unsetDeletableCustomFilterId() {
    this.deletableCustomFilterId = null
  }

  @action.bound
  public setDeletableCustomFilterId(id: string) {
    this.deletableCustomFilterId = id
  }

  @action.bound
  public deleteCustomFilterById(id?: string): void {
    const idToDelete = id || this.deletableCustomFilterId
    if (!idToDelete) {
      return
    }

    this.deletableCustomFilterId = null
    this.customWorkflowsListFiltersStore.removeOne(idToDelete)
    this.removeSavedViewSettings(idToDelete)

    if (idToDelete === this.appliedCustomFilterId) {
      this.resetCustomFilter()
      this.resetAllFilters()
    }
  }

  @action.bound
  public editCustomFilter(): void {
    if (!this.selectedCustomFilter) {
      return
    }
    this.customFilterInstance = this.selectedCustomFilter
    this.editableFilterName = this.selectedCustomFilter.name
    this.editableFilterIsPublic = this.selectedCustomFilter.isPublic
    this.filterDialogMode = CustomFilterDialogModes.Edit
    this.shouldSaveFilterDialogShow = true
  }

  @action.bound
  public startSavedViewEditing(
    id: string,
    field: SavedViewEditableFields,
  ): void {
    if (!id) {
      return
    }

    this.isEditingSavedView = true
    this.editableSavedViewId = id

    this.editableSavedViewName = this.editableSavedView.name
    this.editableSavedViewIsPublic = this.editableSavedView.isPublic
    this.editableSavedViewField = field
  }

  @action.bound
  public finishSavedViewEditing(): void {
    if (!this.editableSavedViewId) {
      return
    }

    if (this.isSavedViewEdited) {
      this.editableSavedView.name = this.editableSavedViewName
      this.editableSavedView.isPublic = this.editableSavedViewIsPublic

      this.saveGivenCustomFilter(this.editableSavedView)
    }

    this.clearEditableSavedView()
  }

  @action.bound
  public clearEditableSavedView(): void {
    this.editableSavedViewId = null
    this.editableSavedViewName = null
    this.editableSavedViewIsPublic = false
    this.editableSavedViewField = SavedViewEditableFields.None
    this.isEditingSavedView = false
  }

  @action.bound
  private async addGivenCustomFilter(
    customFilter: CustomWorkflowsFilter,
  ): Promise<string> {
    const savedCustomFilterId = await this.saveGivenCustomFilter(customFilter)
    this.addSavedViewSettings()
    return savedCustomFilterId
  }

  @action.bound
  private async saveGivenCustomFilter(
    customFilter: CustomWorkflowsFilter,
  ): Promise<string> {
    if (!customFilter) {
      return
    }

    const savedCustomFilterId =
      await this.customWorkflowsListFiltersStore.saveCustomFilter(customFilter)

    return savedCustomFilterId
  }

  @action.bound
  public async saveCustomFilter(): Promise<void> {
    if (!this.editableFilterName?.trim()) return

    this.customFilterInstance.name = this.editableFilterName
    this.customFilterInstance.isPublic = this.editableFilterIsPublic

    const savedCustomFilterId = await this.addGivenCustomFilter(
      this.customFilterInstance,
    )

    if (savedCustomFilterId) {
      this.addSavedViewSettings()
      this.selectCustomFilterById(savedCustomFilterId)
      this.applyCustomFilter()
    }

    this.closeSavedFilters()
  }

  @action.bound
  public duplicateCustomFilterById(id: string): Promise<string> {
    if (!id) {
      return
    }

    const existingCustomFilter =
      this.customWorkflowsListFiltersStore.byId.get(id)

    if (!existingCustomFilter) {
      return
    }

    return this.duplicateCustomFilter(existingCustomFilter)
  }

  @action.bound
  private async duplicateCustomFilter(
    customFilter: CustomWorkflowsFilter,
  ): Promise<string> {
    const duplicate =
      this.customWorkflowsListFiltersStore.createCopy(customFilter)

    duplicate.isPublic = false

    return this.addGivenCustomFilter(duplicate)
  }

  public toggleAddSavedViewDialog = () => {
    if (this.isShown && this.shouldSaveFilterDialogShow) return

    if (this.shouldSaveFilterDialogShow) {
      return this.toggleSaveFilterDialog()
    }

    this.onSaveFiltersClicked()
  }

  public toggleSavedViewsDialog = () => {
    if (this.shouldSaveFilterDialogShow) {
      this.toggleSaveFilterDialog()
    }
    this.toggleSavedFilters()
  }

  @action.bound
  public onSaveFiltersClicked(): void {
    const codeFilterValuesByTypeMap: Map<string, string[]> = new Map()

    this.enabledFilterTypes.forEach(filterType => {
      codeFilterValuesByTypeMap.set(
        filterType,
        this.getSelectedFilterValues(filterType),
      )
    })
    this.createCustomFilterInstance(codeFilterValuesByTypeMap)
    this.showSaveFilterDialog()
  }

  @action.bound
  public applyCustomFilter(): void {
    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.selectedCustomFilter

    workflowsFiltersByFilterType.forEach(({ type, values = [] }) => {
      if (isFilterTypeRelevantForViewMode(type)) {
        this.applyFilterValuesForWorkflowsList(type, values)
      }
    })

    this.logisticsFilterStore.syncFilters()
    this.eventsStore.appState.filters.appliedCustomFilterId =
      this.selectedCustomFilterId

    this.saveCurrentViewId()
  }

  @action.bound
  public setSelectedView() {
    const { user, activeProject } = this.eventsStore.appState
    const preferences = LocalStorageHelper.getUserPreferenceByKey(
      user.id,
      LocalStorageKey.WorkflowSavedView,
    )

    const viewId = preferences?.[activeProject.code]

    if (viewId && this.customFilters.some(f => f.id === viewId)) {
      this.selectCustomFilterById(viewId)
      this.applyCustomFilter()
    }
  }

  public dispatchSetSelectedView = () => {
    this.eventsStore.dispatch(SET_FILTER_SELECTION)
  }

  public resetAll = (shouldSkipSavingView?: boolean) => {
    this.resetCustomFilter()
    this.resetAllFilters()

    this.closeSavedFilters()

    if (!shouldSkipSavingView) {
      this.saveCurrentViewId()
    }
  }

  public resetAllWithoutClose = (shouldSkipSavingView?: boolean) => {
    this.resetCustomFilter(true)
    this.resetAllFilters()
    this.clearSavedFilters()

    if (!shouldSkipSavingView) {
      this.saveCurrentViewId()
    }
  }

  public getSelectedFilterValues(
    filterType: string,
    filterValues?: string[],
    isDescription?: boolean,
  ): string[] {
    const { fieldsMap } = this.filters
    const values =
      filterValues ||
      Array.from(fieldsMap[filterType].selectedFilterOptions.keys())

    return !isDescription
      ? values
      : this.getFilterValueDescription(filterType, values)
  }

  public getSelectedFilters(
    filterType: string,
    filterValues?: string[],
  ): FilterValue[] {
    const { fieldsMap } = this.filters
    const values =
      filterValues ||
      Array.from(fieldsMap[filterType].selectedFilterOptions.keys())

    return this.getFilterValues(filterType, values)
  }

  public getFilterValues(type: string, values: string[]): FilterValue[] {
    switch (type) {
      case WorkflowsFilterType.Status:
        return values

      case WorkflowsFilterType.Company:
        return values.map(value => this.companiesStore.getCompanyById(value))

      case WorkflowsFilterType.ResponsibleContact:
        return this.projectMembersStore.getByIds(values)

      case WorkflowsFilterType.Type:
        return values.map(value =>
          this.permitTypesStore.getLastUpdatedTypeByType(value),
        )

      case WorkflowsFilterType.Location:
      case WorkflowsFilterType.Equipment:
        return values
          .filter(key => key != UNASSIGNED)
          .map(key => {
            const id = key.split(LOCATION_SEPARATOR)[1]
            const item = this.locationAttributesStore.getById(id)
            return item
          })

      default:
        return values || [NO_VALUE]
    }
  }

  public getFilterValueDescription(type: string, values: string[]): string[] {
    switch (type) {
      case WorkflowsFilterType.Status:
        return values
      case WorkflowsFilterType.Company:
        return values.map(
          value => this.companiesStore.getCompanyNameById(value) || value,
        )
      case WorkflowsFilterType.ResponsibleContact:
        return values.map(
          value => this.projectMembersStore.getById(value)?.fullName || value,
        )
      case WorkflowsFilterType.Type:
        return values.map(
          value =>
            this.permitTypesStore.getLastUpdatedTypeByType(value)?.name ||
            value,
        )
      case WorkflowsFilterType.Location:
      case WorkflowsFilterType.Equipment:
        return values
          .filter(key => key !== UNASSIGNED)
          .map(key => {
            const [type, id] = key.split(LOCATION_SEPARATOR)
            const itemName = this.getItemNameByLocationTypeAndId(
              type as LocationType,
              id,
            )
            return itemName
          })
      default:
        return values || [NO_VALUE]
    }
  }

  protected createCustomFilterInstance(
    codeFilterValuesByTypeMap: Map<string, string[]>,
  ): void {
    this.customFilterInstance = this.createCustomFilterInstanceFromMap(
      codeFilterValuesByTypeMap,
    )
  }

  private createCustomFilterInstanceFromMap(
    codeFilterValuesByTypeMap: Map<string, string[]>,
  ): CustomWorkflowsFilter {
    const codesByType: IWorkflowsFilter[] = []
    codeFilterValuesByTypeMap.forEach((codeIds, typeId) => {
      codesByType.push({
        values: (codeIds || []).map(code => code || UNASSIGNED),
        type: typeId as WorkflowsFilterType,
      })
    })

    return this.customWorkflowsListFiltersStore.createFromValues(
      codesByType,
      this.editableFilterIsPublic,
      [],
      this.logisticsGroupingOptionsStore.appliedOption.id,
    )
  }

  private applyFilterValuesForWorkflowsList(
    type: WorkflowsFilterType,
    values: string[] = [],
  ): void {
    const filterStore = this.logisticsFilterStore.filterStoresByTypeMap[type]
    if (!filterStore) {
      return
    }

    filterStore.clickOnSelectAll()
    Array.from(filterStore.filter.selectedFilterOptions.keys()).forEach(
      option => {
        if (values && !values.includes(option)) {
          filterStore.selectedOptions.delete(option)
        }
      },
    )
    filterStore.clickOnApply()
  }

  private workflowsInSelectedFilters = (
    workflowsFiltersByFilterType?: IWorkflowsFilter[],
  ): Permit[] => {
    const { formsInPeriodInterval } = this.logisticsStore
    const relevantFilters = workflowsFiltersByFilterType.filter(fieldType =>
      isFilterTypeRelevantForViewMode(fieldType.type),
    )

    return formsInPeriodInterval.filter(workflow => {
      return relevantFilters.every(filterType =>
        this.isWorkflowInFilterType(workflow, filterType),
      )
    })
  }

  private isWorkflowInFilterType = (
    workflow: Permit,
    { type, values }: IWorkflowsFilter,
  ): boolean => {
    switch (type) {
      case WorkflowsFilterType.Status:
        if (!values.includes(workflow.status)) {
          return false
        }
        break
      case WorkflowsFilterType.Company:
        if (
          (!workflow.companyIds.length && !values.includes(UNASSIGNED)) ||
          (workflow.companyIds.length &&
            !values.some(v => workflow.companyIds.includes(v)))
        ) {
          return false
        }
        break
      case WorkflowsFilterType.ResponsibleContact:
        if (!values.some(v => workflow.responsibleIds.includes(v))) {
          return false
        }
        break
      case WorkflowsFilterType.Type:
        const permitType = this.permitTypesStore.getPermitTypeById(
          workflow.typeId,
        )
        if (
          (!permitType && !values.includes(UNASSIGNED)) ||
          (permitType && !values.includes(permitType.type))
        ) {
          return false
        }
        break
      case WorkflowsFilterType.Location:
        if (
          (!workflow.locations.length && !values.includes(UNASSIGNED)) ||
          (workflow.locations.length &&
            !values.some(v =>
              workflow.locations.some(l => {
                const [type, id] = v.split(LOCATION_SEPARATOR)
                return l.type === type && l.id === id
              }),
            ))
        ) {
          return false
        }
        break
      case WorkflowsFilterType.Equipment:
        if (
          (!workflow.equipment.length && !values.includes(UNASSIGNED)) ||
          (workflow.equipment.length &&
            !values.some(v =>
              workflow.equipment.some(l => {
                const [type, id] = v.split(LOCATION_SEPARATOR)
                return l.type === type && l.id === id
              }),
            ))
        ) {
          return false
        }
        break
    }

    return true
  }

  private saveCurrentViewId = () => {
    const { user, activeProject, filters } = this.eventsStore.appState

    const preferences =
      LocalStorageHelper.getUserPreferenceByKey(
        user.id,
        LocalStorageKey.WorkflowSavedView,
      ) || {}

    Object.assign(preferences, {
      [activeProject.code]: filters.appliedCustomFilterId,
    })

    LocalStorageHelper.setUserPreferences(user.id, {
      [LocalStorageKey.WorkflowSavedView]: preferences,
    })
  }

  private shouldIncludeInSelectedFiltersMap = (
    filter: IWorkflowsFilter,
  ): boolean => {
    return filter.values?.length && !this.areAllFilterOptionsSelected(filter)
  }

  private areAllFilterOptionsSelected = (filter: IWorkflowsFilter): boolean => {
    const sourceMap = this.logisticsFilterStore.filterStoresByTypeMap

    if (filter.values.length < sourceMap[filter.type].allOptionIds.length) {
      return false
    }

    return !sourceMap[filter.type].allOptionIds.some(
      optionId => !filter.values.includes(optionId),
    )
  }

  @action.bound
  private addSavedViewSettings() {
    const collection = this.savedViewsSettings.slice()

    const firstHiddenSavedViewIndex = collection.findIndex(
      savedViewSettings => savedViewSettings.isHidden,
    )

    this.reorderSavedViewSettings(
      collection,
      collection.length - 1,
      firstHiddenSavedViewIndex,
    )
  }

  @action.bound
  private removeSavedViewSettings(savedViewId: string) {
    const collection = this.savedViewsSettings
      .slice()
      .filter(
        savedViewSettings =>
          savedViewSettings.workflowCustomFilterId !== savedViewId,
      )

    this.updateSavedViewsSettings(collection)
  }

  @action.bound
  public updateSavedViewsSettings(collection: ISavedViewSettings[]) {
    const userActiveProjectSettings = this.userActiveProjectSettings.toDto()
    userActiveProjectSettings.savedViewsSettings = collection

    this.userProjectsStore.save([userActiveProjectSettings])
  }

  @action.bound
  public reorderSavedViewSettings(
    collection: ISavedViewSettings[],
    oldIndex: number,
    newIndex: number,
    newSavedViewSettings?: ISavedViewSettings,
  ) {
    const newCollection = arrayMove(collection, oldIndex, newIndex)

    if (newSavedViewSettings) {
      newCollection[newIndex] = {
        ...newSavedViewSettings,
      }
    }

    this.updateSavedViewsSettings(newCollection)
  }

  @action.bound
  private generateSavedViewsSettings(): ISavedViewSettings[] {
    return this.customFilters.map(
      savedView =>
        ({
          workflowCustomFilterId: savedView.id,
          isHidden: false,
        } as ISavedViewSettings),
    )
  }

  public get isActiveUserAdmin() {
    return this.customWorkflowsListFiltersStore.isActiveUserAdmin
  }
}
